/* Container for centering MasterPlanLayout */
.master-plan-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;     /* Center vertically, if needed */
    /* margin-bottom: 10px; 
        */
       
  }
  
  /* Adjustments for MasterPlanLayout */
  .master-plan-container img {
    max-width: 100%; /* Ensure the image scales properly */
    height: auto;    /* Maintain aspect ratio */
  }

  .floor-plan-card-container{
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;     /* Center vertically, if needed */
    /* padding: 20px; */
    /* margin-bottom: 20px;  */
  }

  .title-container{
    padding-left: 25px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .master-plan-container {
      padding: 0px; /* Adjust padding for smaller screens */
      margin: 0px;
    }

    .floor-plan-card-container{
        flex-direction: column;
    }
  }
  