/* General styling for the section */
.overview-section {
    padding: 10px 0px;
    /* background: linear-gradient(135deg, #ffffff, #f0f0f0); */
    border-top: 3px solid #ddd;
    font-family: 'Roboto', sans-serif;
  }
  
  .overview-container {
    width: 100%;
    margin: 0 auto;
    background-color: #fff;
    /* border-radius: 15px; */
    overflow: hidden;
    transition: transform 0.3s ease;
    /* box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15); */
  }
  
  .overview-container:hover {
    transform: translateY(-8px);
  }
  
  /* Content styling */
  .overview-content {
    padding: 30px;
    color: #333;
    text-align: left;
  }
  
  .overview-content h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 2em;
    font-weight: 400;
    color: #333;
    margin-bottom: 15px;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: relative;
  }
  
  .overview-content h2:after {
    content: '';
    width: 60px;
    height: 4px;
    background: #ff5733;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -10px;
    border-radius: 2px;
  }
  
  .overview-content p {
    font-family: 'Roboto', sans-serif;
    font-size: 1.1em;
    line-height: 1.7;
    margin-bottom: 20px;
    color: #555;
  }
  
  .overview-content strong {
    font-weight: 550;
    color: #333;
  }
  
  .overview-text.collapsed {
    overflow: hidden;
    max-height: 60px;
    transition: max-height 0.5s ease;
  }
  
  .overview-text.expanded {
    max-height: 1000px;
    transition: max-height 0.5s ease;
  }
  
  /* Button group styling */
  .button-group {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .show-more-button, .download-brochure-button {
    padding: 12px 30px;
    font-family: 'Poppins', sans-serif;
    font-size: 1em;
    font-weight: 600;
    color: #fff;
    background-color: #ff5733;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .show-more-button:hover, .download-brochure-button:hover {
    background-color: #e64c2a;
    box-shadow: 0 6px 20px rgba(230, 76, 42, 0.4);
  }
  
  .download-brochure-button {
    display: flex;
    align-items: center;
    background: linear-gradient(135deg, #ff5733, #c70039);
  }
  
  .download-brochure-button i {
    margin-right: 8px;
    font-size: 1.2em;
  }






  /* Project Highlights Section */
.highlights-section {
    margin-top: 20px;
    background-color: #ffffff;
    border: 1px solid #6f6d6d;
    border-radius: 10px;
    padding: 10px;
    /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
  }
  
  .highlights-section h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.6em;
    font-weight: 700;
    color: #000;
    margin-bottom: 15px;
  }
  
  .highlights-section ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .highlights-section li {
    font-family: 'Roboto', sans-serif;
    font-size: 1.1em;
    line-height: 1.6;
    margin-bottom: 10px;
    padding: 5px 0;
    border-bottom: 1px dashed #6f6d6d;
  }
  
  .highlights-section li:last-child {
    border-bottom: none;
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 768px) {
    .overview-content h2 {
      font-size: 2em;
    }
  
    .overview-content p {
      font-size: 1em;
    }
  
    .button-group {
      flex-direction: column;
      align-items: center;
    }
  }
  
  @media (max-width: 480px) {
    .overview-content h2 {
      font-size: 1.8em;
    }
  
    .overview-content p {
      font-size: 0.95em;
    }
  
    .overview-container {
      margin: 0 0px;
    }
  }
  