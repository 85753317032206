.footer {
    background-color: #2C2C2C; /* Dark grey background */
    color: #fff;
    padding: 20px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-section {
    flex: 1;
    margin: 10px;
    min-width: 250px;
  }
  
  .footer-section h3 {
    margin-bottom: 15px;
    font-size: 18px;
    color: #FFEB3B; /* Yellow color for headings */
  }
  
  .footer-section p, .footer-section ul {
    font-size: 14px;
    line-height: 1.6;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 10px;
  }
  
  .footer-section ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    transition: color 0.3s ease, transform 0.3s ease;
  }
  
  .footer-section ul li a:hover {
    color: #FFEB3B; /* Yellow on hover */
    transform: translateX(5px); /* Slight movement on hover */
  }
  
  .footer-icon {
    margin-right: 10px;
    color: #FFEB3B;
    transition: color 0.3s ease, transform 0.3s ease;
  }
  
  .footer-icon:hover {
    color: #fff; /* White on hover */
    transform: scale(1.2); /* Slightly larger on hover */
  }
  
  .footer-bottom {
    margin-top: 20px;
    font-size: 14px;
    border-top: 1px solid #fff;
    padding-top: 10px;
    width: 100%;
    max-width: 1200px;
  }
  
  @media (max-width: 768px) {


    .footer {
      
      padding: 0px 0;
      
    }



    .footer-content {
      flex-direction: column;
      text-align: left;
    }


    
  }
  