/* PreRegisterForm.css */
.pre-register-form {
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
  }
  
  .pre-register-form h3 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  
  .pre-register-form form {
    display: flex;
    flex-direction: column;
  }
  
  .pre-register-form input {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .pre-register-form button {
    background-color: #000;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .pre-register-form button:hover {
    background-color: #444;
  }
  
  .pre-register-form p {
    margin-top: 10px;
    font-size: 14px;
    text-align: center;
  }
  
  .pre-register-form p a {
    color: #ff4c4c;
    text-decoration: none;
  }
  