.custom-carousel .legend {
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 10px;
    font-size: 16px;
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-radius: 10px;
  }
  
  .custom-carousel img {
    border-radius: 10px;
    max-height: 400px;
    object-fit: cover;
  }
  
  .custom-carousel .control-dots .dot {
    background-color: #fff;
    opacity: 0.7;
  }
  
  .custom-carousel .control-dots .dot.selected {
    background-color: #3f51b5;
    opacity: 1;
  }
  