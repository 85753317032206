/* SocialShare.css */
.social-share {
    text-align: center;
    margin-bottom: 15px;
  }
  
  .social-share p {
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .social-share .social-icons {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  
  .social-share .social-icons a {
    color: #333;
    font-size: 24px;
    transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
  
  .social-share .social-icons a:hover {
    color: #007bff;
    transform: scale(1.2);
  }
  