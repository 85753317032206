/* SiteVisitInfo.css */
.site-visit-info {
    background-color: #000;
    color: white;
    padding: 15px;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 15px;
  }
  
  .site-visit-info button {
    background-color: #ff4c4c;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
    margin-bottom: 15px;
  }
  
  .site-visit-info button:hover {
    background-color: #ff0000;
    transform: scale(1.05);
  }
  
  .site-visit-info ul {
    list-style-type: none;
    padding: 0;
  }
  
  .site-visit-info ul li {
    font-size: 14px;
    margin-bottom: 5px;
  }
  