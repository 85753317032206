/* General Styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(135deg, #0a0a0a 0%, #222324 100%);
  color: #ffffff;
  padding: 15px 30px;
  position: fixed;
  width: 100%;
  z-index: 1000;
  transition: background-color 0.3s ease, padding 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.header-left .header-logo-img {
  width: 100px;
  height: 50px;
  border-radius: 5px;
}

.header-center {
  flex-grow: 1;
}

.header-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  height: 100%;
}

.header-nav-item {
  margin: 0 20px;
  text-decoration: none;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  transition: color 0.3s ease;
  white-space: nowrap;
}

.header-nav-item:hover {
  color: #ffd700;
  transform: translateY(-2px);
}

.header-nav-item::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  width: 0;
  height: 3px;
  background-color: #ffd700;
  transition: width 0.3s ease, left 0.3s ease;
}

.header-nav-item:hover::after {
  width: 100%;
  left: 0;
}

.header-nav-icon {
  margin-right: 8px;
  font-size: 20px;
  transition: transform 0.3s ease;
}

.header-nav-item:hover .header-nav-icon {
  transform: rotate(20deg);
}

.header-right {
  display: flex;
  align-items: center;
}

.hamburger {
  font-size: 28px;
  color: #ffffff;
  background: none;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.hamburger:hover {
  transform: rotate(90deg);
}



.mobile-logo{
  display: none;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .header {
    padding: 0px 0px;
    flex-direction: column;
    align-items: flex-start;
    background-color: #000;
    
  }

  .header-center {
    order: 2;
    width: 100%;
  
    /* border-top: 1px solid white; */
  }

  .header-nav {
    flex-direction: column;
    display: none;
    width: 100%;
    background-color: white;
    /* background: linear-gradient(135deg, #0a0a0a 0%, #222324 100%); */
   
  }

  .header-nav.open {
    display: flex;
    animation: slideIn 0.3s ease forwards;
    margin: 0px;
    padding: 20px;
  }

  .header-nav-item {
    width: 98%;
    text-align: left;
    color: #000;
    padding: 5px 5px;
    margin: 5px 0px;
    /* border-top: 1px solid rgb(92, 90, 90); */
    
  }


  
.header-nav-item:hover {
  color: #000;
  transform: translateY(-2px);
}

  .header-right .header-icons {
    display: block;
    padding: 10px 20px;
  }

  .mobile-logo {
    display: block;
    align-self: flex-end;
    /* margin-left: auto; */
    margin-left: 50px;
    border-radius: 0px;
  }

  .header-left {
    display: none;
  }
}

/* Slide-in animation for mobile menu */
@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
