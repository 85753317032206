/* FooterIcons.css */
.footer-icons {
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
    border-top: 1px solid #ddd;
  }
  
  .footer-icons i {
    font-size: 24px;
    color: #333;
    transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
  
  .footer-icons i:hover {
    color: #007bff;
    transform: scale(1.2);
  }
  