/* BottomNav.css */

.bottom-nav {
    width: 100%;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    background-color: #000; /* Black background */
    color: #fff; /* White text color */
    padding: 10px 0;
    border-top: 2px solid #444; /* Slightly lighter border for visibility */
    z-index: 2000;
    box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.3); /* Shadow for a floating effect */
  }
  
  .bottom-nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    text-decoration: none;
    color: #fff; /* White icon and text color */
    transition: transform 0.3s ease, color 0.3s ease; /* Smooth transition for hover effects */
    padding: 5px;
  }
  
  .bottom-nav-item:hover {
    color: #00bfa5; /* Change color on hover */
  }
  
  .bottom-nav-item .icon {
    font-size: 24px; /* Icon size */
    margin-bottom: 5px;
  }
  
  .label {
    font-size: 12px; /* Label size */
    font-weight: 500; /* Slightly bold text for label */
    margin-top: 3px; /* Add space between icon and label */
  }
  
  @media (max-width: 600px) {
    .bottom-nav-item .icon {
      font-size: 20px; /* Smaller icons on mobile */
    }
  
    .label {
      font-size: 10px; /* Smaller labels on mobile */
    }
  }
  