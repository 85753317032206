/* Overlay */
.brochure-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Dialog Container */
  .brochure-dialog {
    background: #fff;
    border-radius: 8px;
    max-width: 900px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  /* Close Button */
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
    color: white;
  }
  
  /* Title */
  .brochure-dialog-title {
    text-align: center;
    /* margin-bottom: 20px; */
    font-size: 24px;
    font-weight: bold;
    color: white;
    background: #000;
    margin-top: 0px;
    padding: 10px;
    margin-bottom: 0px;
  }
  
  /* Content Section */
  .brochure-dialog-content {
    display: flex;
    flex-direction: row;
    justify-content:left;
    gap: 20px;
  }
  
  .promise-section {
    /* flex: 1; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  
  .promise-item {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    margin-left: 10px;
  }


  .promise-item p{
  color: black;
  }
  
  .promise-item img {
    width: 40px;
    height: 40px;
  }
  
  /* Form Section */
  .brochure-form {
    /* flex: 1; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #e4e1e1;
    margin-bottom: 10px;
    padding: 10px;
    margin-top: 0px;
  }
  
  .brochure-form h3 {
    font-size: 20px;
    margin-bottom: 20px;
    text-align: left;
    color: black;
  }
  
  .brochure-form input {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .brochure-form button {
    padding: 10px;
    background-color: #ff4c4c;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .brochure-form button:hover {
    background-color: #0056b3;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .brochure-dialog-content {
      flex-direction: column;
    }


    .promise-item {
        display: none;
    }
  
    .promise-section,
    .brochure-form {
      flex: none;
    }
  }
  