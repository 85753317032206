/* PricingSection.css */

/* Pricing and Enquiry Container */
.pricing-enquiry-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Pricing Section */
.pricing-section {
    flex: 2;
    margin-right: 20px;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
   
    
}

.pricing-header h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

/* Pricing Table */
.pricing-table {
    width: 100%;
    display: flex;
    flex-direction: column;
   
}

.pricing-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.pricing-row:last-child {
    border-bottom: none;
}

.pricing-type, .pricing-area, .pricing-price {
    flex: 1;
    text-align: left;
    font-size: 16px;
    color: #555;
}

/* Attractive "Click Here" Button */
.action-button {
    padding: 8px 16px;
    background: linear-gradient(45deg, #f093fb, #f5576c);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
    outline: none;
}

.action-button:hover {
    background: linear-gradient(45deg, #f77062, #fe5196);
    transform: translateY(-3px);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
}

.action-button:active {
    transform: translateY(-1px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

/* Enquiry Section */
.enquire-section {

    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* transition: background-color 0.3s ease; */
    transition: background 0.4s ease-in-out;
}




/* Responsive adjustments */
@media (max-width: 768px) {
    .pricing-enquiry-container {
        flex-direction: column;
        width: 100%;
        margin: 0px;
        padding: 0px;
    }

    .pricing-section {
        margin-right: 0;
        margin-bottom: 10px;
    }

    .enquire-section {
        width: 100%;
    }


    .pricing-row{
        display: flex;
        flex-direction: column;
        width: 88vw;
        margin: 5px 0px;
       background-color: #ddd;
      
       
    }

    .pricing-type{
        margin: 10px;
        font-size: 30px;
    }


    .pricing-area{
        margin-bottom: 5px;
        font-size: 20px;
    }

    .pricing-price{
        margin-bottom: 10px;
        font-size: 20px;
    }

    .pricing-table{
        border: 1px solid #ddd;
        border-radius: 3px;

       
    }


   
}
