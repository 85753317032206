/* src/FAQ.css */
.faq-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 10px auto;
    width: 100%;
    /* background: red; */
  }
  
  .faq-label {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    /* background-color: #000; */
    color: #000;
    padding: 10px;
    font-weight: bold;
    text-align:right;
    margin-right: 20px;
    font-size: 34px;
    height: 100%;
  }



  .faq-label2 {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    /* background-color: #000; */
    color: #000;
    padding: 10px;
    font-weight: bold;
    text-align:right;
    margin-right: 20px;
    font-size: 34px;
    height: 100%;
    display: none;
  }
  
  .faq-section {
    width: 100%;
  }
  
  .faq-section h2 {
    text-align: left;
    margin-bottom: 30px;
    font-size: 34px;
    font-weight: bold;
  }
  
  .faq-item {
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .faq-question {
    font-weight: bold;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  
  .faq-icon {
    margin-right: 10px;
    color: #333;
  }
  
  .faq-answer {
    color: #333;
    margin-left: 30px;
  }
  
  @media (max-width: 768px) {
    .faq-container {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .faq-label {
      writing-mode: horizontal-tb;
      transform: rotate(0);
      margin-bottom: 10px;
      text-align: left;
      display: none;
    }


    .faq-label2 {
        display: flex;
        writing-mode: horizontal-tb;
        transform: rotate(0);
        margin: 0;
        text-align: left;
      }
  
    .faq-section h2 {
      text-align: left;
      margin-bottom: 0;
    }
  }
  