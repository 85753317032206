.map-view-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
    /* margin: 20px; */
    /* background: red; */
  }
  
  .map-section {
    /* background: red; */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 0px 10px;
    align-items: center;
  }
  
  .map-section h2 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  
  .map iframe,
  .location-map-img {
    width: 100%;
    border-radius: 10px;
  }
  
  @media screen and (max-width: 768px) {
    .map-view-container {
      grid-template-columns: 1fr;
    }


    .location-map{
      margin: 0px;
      padding: 0px;
    }
  }
  