/* TopButtons.css */
.top-buttons {
    display: flex;
    justify-content: space-around;
    margin-bottom: 15px;
  }
  
  .top-buttons button {
    background-color: #333;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
  
  .top-buttons button:hover {
    background-color: #555;
    transform: scale(1.05);
  }
  