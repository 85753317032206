.image-gallery-custom-left-nav,
.image-gallery-custom-right-nav {
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: black;
  background: #fff;
  font-size: 2rem;
  padding: 10px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  border-radius: 100px;
  cursor: pointer;
  position: absolute;
  border-radius: 10;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

.image-gallery-custom-left-nav {
  left: 10px;
}

.image-gallery-custom-right-nav {
  right: 10px;
}

.image-gallery-custom-left-nav:hover,
.image-gallery-custom-right-nav,
.image-gallery-custom-right-nav:hover {
  background-color: rgba(235, 56, 16, 0.8);
  color: white;
}
