.virtual-site-tour {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Space between video and title */
    /* padding: 20px; */
    /* background-color: #938f8f; */
    height: 100vh; /* Full viewport height */
    box-sizing: border-box;
    margin-top: 5px;
    border-radius: 5px;
  }
  
  .tour-video {
    flex: 1; /* Take up the remaining space */
    height: 100%; /* Ensure the video height is consistent */
  }
  
  .tour-video iframe {
    width: 100%; /* Video takes up all available width */
    height: 100%; /* Video takes up all available height */
  }
  
  .tour-title {
    font-size: 34px;
    writing-mode: vertical-rl; /* Rotate text vertically */
    text-orientation: mixed;
    margin-left: 20px; /* Space between video and title */
    font-weight: bold;
    height: 100%; /* Ensure the title is full height */
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }


  .tour-title2 {
    font-size: 34px;
    writing-mode: vertical-rl; /* Rotate text vertically */
    text-orientation: mixed;
    margin-left: 20px; /* Space between video and title */
    font-weight: bold;
    height: 100%; /* Ensure the title is full height */
    display: none;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  @media screen and (max-width: 768px) {
    .virtual-site-tour {
      flex-direction: column;
      justify-content: center;
      height: auto;
    }
  
    .tour-title {
      writing-mode: horizontal-tb; /* Revert to horizontal for small screens */
      text-align: center;
      margin-left: 0;
      margin-top: 20px;
      display: none;
    }

    .tour-title2 {
        display: flex;
        writing-mode: horizontal-tb; /* Revert to horizontal for small screens */
        text-align:start;
        margin-left: 0;
        margin-top: 20px;
      }
  
    .tour-video {
      width: 100%;
      height: auto;
    }
  
    .tour-video iframe {
      height: auto;
    }
  }
  
  @media screen and (max-width: 480px) {
    .tour-title2 {
      font-size: 24px;
    }
  }
  