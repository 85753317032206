/* MainLayout.css */

.main-layout {
  display: flex;
  height: 100vh; 
  transition: flex-direction 0.3s ease; 
}

/* Left Section (Header) */
.left-section {
  width: 280px; 
  background-color: #f4f4f4; 
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); 
  /* overflow-y: auto;  */
  overflow: hidden;

}

/* Right Section */
.right-section {
  flex: 1; 
  background-color: #fbfafa; 
  overflow-y: auto; 
  padding: 10px;
  margin-top: 80px;
 
}


#pre-register-form {
  display: none;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .main-layout {
    flex-direction: column; 
  }

  .right-section {
    padding: 10px;
    margin-top: 50px;
  }

  .left-section {
    display: none;
  }


  #pre-register-form {
    display: block;
  }
}
