.floor-plan-container {
    display: inline-block;
    margin: 10px;
    width: 100%;
    
  }
  
  .floor-plan-card {
    position: relative;
    width: 100%;
    height: 17rem;
    overflow: hidden;
    border: 1px solid #160303;
    transition: all 0.3s ease-in-out;
  }
  
  .card-image {
    width: 100%;
    object-fit: cover;
    opacity: 0.2; /* Lower opacity for a faded effect */
    filter: blur(2px); /* Add blur effect */
    transition: opacity 0.3s ease-in-out, filter 0.3s ease-in-out;
  }
  
  .card-body {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0;
    background-color: transparent; /* Transparent background to let the image show */
    z-index: 1; /* Ensure it stays on top of the image */
  }
  
  .card-title {
    color: white;
    text-align: center;
    padding: 10px 10px;
    font-size: 20px;
    background-color:#000;
    margin: 0;
  }
  
  .card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease-in-out;
  }
  
  .enquire-button {
    position: absolute;
    top: -50px; /* Start above the card */
    border: 2px solid white;
    border-radius: 0;
    color: white;
    background: #000;
    padding: 10px 20px;
    cursor: pointer;
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }
  
  .floor-plan-card:hover .card-overlay {
    opacity: 1;
  }
  
  .floor-plan-card:hover .enquire-button {
    top: 50%; /* Move to the middle of the card */
    opacity: 1;
    transform: translateY(-50%);
  }
  