/* RightSection.css */
.left-sec {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
}

@media (max-width: 768px) {
  .left-sec {
    width: 100%;
  }
}

.top-buttons button,
.pre-register-form button,
.site-visit-info button {
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .social-icons a {
    display: inline-block;
    margin: 0 10px;
  }
}
